import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Sprawdź, czy element o id "root" istnieje
let rootElement = document.getElementById('root');

// Jeśli nie istnieje, dynamicznie go stwórz
if (!rootElement) {
  rootElement = document.createElement('div');
  rootElement.id = 'root';
  document.body.appendChild(rootElement);  // Dodaj go do body
}

const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Jeśli chcesz logować wyniki metryki
reportWebVitals();
