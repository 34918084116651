import axios from 'axios';

const API_URL = 'http://localhost:3000/api'; // Zaktualizuj URL zgodnie z konfiguracją backendu

// Funkcje do wywoływania endpointów articles
export const getArticles = async () => {
  try {
    const response = await axios.get(`${API_URL}/articles`);
    return response.data;
  } catch (error) {
    console.error('Error fetching articles:', error);
    throw error;
  }
};

export const createArticle = async (articleData) => {
  try {
    const response = await axios.post(`${API_URL}/articles`, articleData);
    return response.data;
  } catch (error) {
    console.error('Error creating article:', error);
    throw error;
  }
};

// Funkcje do wywoływania endpointów pages
export const getPages = async () => {
  try {
    const response = await axios.get(`${API_URL}/pages`);
    return response.data;
  } catch (error) {
    console.error('Error fetching pages:', error);
    throw error;
  }
};

export const createPage = async (pageData) => {
  try {
    const response = await axios.post(`${API_URL}/pages`, pageData);
    return response.data;
  } catch (error) {
    console.error('Error creating page:', error);
    throw error;
  }
};

// Funkcje do wywoływania endpointów navigation
export const createNavigationItem = async (navigationData) => {
  try {
    const response = await axios.post(`${API_URL}/navigation`, navigationData);
    return response.data;
  } catch (error) {
    console.error('Error creating navigation item:', error);
    throw error;
  }
};

export const deleteNavigationItem = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/navigation/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting navigation item:', error);
    throw error;
  }
};

// Funkcje do wywoływania endpointów auth (przykładowe, implementacja wymagana w backendzie)
export const login = async (credentials) => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, credentials);
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

export const register = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/auth/register`, userData);
    return response.data;
  } catch (error) {
    console.error('Error registering:', error);
    throw error;
  }
};
