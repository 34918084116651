// src/components/Navigation.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navigation.css';

const Navigation = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <header>
      <nav className="main-nav">
        <div className="hamburger" onClick={toggleNav}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <ul className={isNavOpen ? 'active' : ''}>
          <li><Link to="/">Strona Główna</Link></li>
          <li><Link to="/artykuly">Artykuły</Link></li>
          <li><Link to="#">Zaloguj</Link></li>
          <li><Link to="#temat-1">Open source</Link></li>
          <li><Link to="#temat-4">Dystrybucje linuxa</Link></li>
          <li><Link to="#temat-2">How to setup</Link></li>
          <li><Link to="#temat-3">Hosting Współdzielony</Link></li>
          <li><Link to="#temat-4">Sztuczna Inteligencja</Link></li>
          <li><Link to="/codesamples">Kącik JavaScript</Link></li>
          <li><Link to="/radio_synteza">Radio Synteza</Link></li>
          <li><Link to="/press-release">Informacja Prasowa</Link></li> {/* Dodany link do informacji prasowej */}
        </ul>
      </nav>
    </header>
  );
};

export default Navigation;
