import React from 'react';

const PressRelease = () => {
    return (
        <div>
            <h1>Informacja Prasowa</h1>
            <p><strong>Atak Hakerski na Firmę Agata S.A. - Naruszenie Danych Osobowych</strong></p>
            <p>
                Katowice, 22 czerwca 2024 r. - Firma Agata S.A., z siedzibą w Katowicach, poinformowała o poważnym naruszeniu ochrony danych osobowych, 
                które miało miejsce w wyniku ataku hakerskiego ujawnionego 22 czerwca 2024 roku. Incydent ten dotknął dane osobowe klientów firmy.
            </p>
            <h2>Zakres Naruszenia:</h2>
            <p>
                Naruszenie danych polegało na tymczasowej utracie dostępu do informacji oraz potencjalnym skopiowaniu danych osobowych przez nieuprawnione osoby. 
                Dotknięte dane obejmują imię i nazwisko, adres, dane kontaktowe oraz informacje o zamówieniach i reklamacjach.
            </p>
            <h2>Bezpieczeństwo Danych Finansowych:</h2>
            <p>
                Firma Agata S.A. informuje, że login i hasło do konta klienta oraz dane finansowe dotyczące płatności i kredytów nie były przechowywane ani przetwarzane przez infrastrukturę firmy, co zmniejsza ryzyko wycieku tych danych.
            </p>
            <h2>Działania Podjęte Przez Firmę:</h2>
            <p>
                Po wykryciu naruszenia, Agata S.A. natychmiast podjęła działania mające na celu minimalizację skutków incydentu. Przywrócono kopie danych z systemów zapasowych oraz zabezpieczono dostęp do nich. 
                Sprawa została zgłoszona odpowiednim służbom, w tym Prezesowi Urzędu Ochrony Danych Osobowych.
            </p>
            <h2>Rekomendacje dla Klientów:</h2>
            <p>
                Firma zaleca klientom zachowanie czujności i ostrożności w przypadku podejrzanych kontaktów telefonicznych lub e-mailowych. Klienci proszeni są o unikanie podawania swoich danych osobowych osobom nieznanym oraz o dokładne weryfikowanie informacji podczas rozmów.
            </p>
            <h2>Kontakt dla Klientów:</h2>
            <p>
                W przypadku pytań lub potrzeby uzyskania dodatkowych informacji, klienci mogą skontaktować się z Biurem Obsługi Klienta pod adresem e-mail: kontakt@agatameble.pl lub za pośrednictwem formularza kontaktowego dostępnego na stronie internetowej firmy.
            </p>
            <p>Dziękujemy za zrozumienie i przepraszamy za wszelkie niedogodności związane z tym incydentem.</p>
            <p><strong>Pełnomocnik Bezpieczeństwa Informacji Agata S.A.</strong></p>
            <p>
                <strong>O Wirtualnaredakcja.pl:</strong> Wirtualnaredakcja.pl to serwis online OpenSource, dostarczający najnowsze informacje z różnych dziedzin życia. Działamy na rzecz transparentności i bezpieczeństwa w świecie cyfrowym.
            </p>
            <p>Kontakt dla mediów: Biuro Obsługi Klienta Agata S.A. E-mail: kontakt@agatameble.pl Strona internetowa: <a href="https://agatameble.pl">agatameble.pl</a></p>
        </div>
    );
};

export default PressRelease;
