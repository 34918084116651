import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { getArticles, getPages } from './services/api';
import Navigation from './components/Navigation';
import PressRelease from './PressRelease';
import './App.css';

function App() {
  const [articles, setArticles] = useState([]);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const articlesData = await getArticles();
        setArticles(articlesData);

        const pagesData = await getPages();
        setPages(pagesData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="App">
      <Helmet>
        <title>Wirtualna Redakcja</title>
        <meta name="description" content="Dostarczamy rzetelne informacje o technologii open source." />
        <meta name="keywords" content="open source, technologia, artykuły, informacja" />
        <link rel="icon" type="image/png" href="path/to/favicon.png" sizes="16x16" />
      </Helmet>
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" element={
            <main>
              <section id="intro">
                <h1>Witaj w Wirtualnej Redakcji</h1>
                <p>Dostarczamy rzetelne informacje o technologii open source.</p>
              </section>
              <section className="content">
                {/* Reszta Twojego kodu */}
                <h2>Aktualności</h2>
                <div className="articles-header"><h3>Artykuły z bazy</h3></div>
                <ul>
                  {articles.map(article => (
                    <li key={article._id}>{article.title}</li>
                  ))}
                </ul>
                <section className="articles">
                  <header className="articles-header"><h3>Teoria administracji serwerami</h3></header>
                  <article>
                    <h2>Virtual Hosts w Apache</h2>
                    <p>Tutaj znajduje się ogólny opis dotyczący tematu "Virtual Hosts w Apache".</p>
                  </article>
                  <article>
                    <h2>Konfiguracja Serwerów</h2>
                    <p>Tutaj znajduje się ogólny opis dotyczący tematu "Konfiguracja Serwerów".</p>
                  </article>
                  <article>
                    <h2>Hosting Współdzielony</h2>
                    <p>Tutaj znajduje się ogólny opis dotyczący tematu "Hosting Współdzielony".</p>
                  </article>
                </section>
                <section className="articles">
                  <header className="articles-header"><h3>Teoretyczne podstawy informatyki</h3></header>
                  <article>
                    <h2>Wprowadzenie do informatyki</h2>
                  </article>
                  <article>
                    <h2>Jednostki w teorii informacji</h2>
                  </article>
                  <article>
                    <h2>Matematyka i elektronika</h2>
                  </article>
                </section>
                <section className="articles">
                  <header className="articles-header"><h3>Informatyka stosowana</h3></header>
                  <article>
                    <h2>Programowanie</h2>
                  </article>
                  <article>
                    <h2>Algorytmy i struktury danych</h2>
                  </article>
                  <article>
                    <h2>Bazy danych</h2>
                  </article>
                  <article>
                    <h2>Sztuczna Inteligencja</h2>
                    <p>Tutaj znajduje się ogólny opis dotyczący tematu "Sztuczna Inteligencja".</p>
                  </article>
                </section>
                <section className="authors">
                  <nav className="footer-nav">
                    <ul>
                      <li><a href="#">Redakcja</a></li>
                    </ul>
                  </nav>
                </section>
                <section className="contact">
                  <nav className="footer-nav">
                    <ul>
                      <li><a href="#">Kontakt</a></li>
                    </ul>
                  </nav>
                </section>
                <footer>
                  <div className="footer-content">
                    <p>&copy; 2023 Wirtualna Redakcja</p>
                  </div>
                </footer>
              </section>
            </main>
          } />
          <Route path="/press-release" element={<PressRelease />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
